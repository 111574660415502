import * as React from "react"

/* Import Global Component(s) */
import Header from "~components/header/header"
import Seo from "~components/seo/seo"

/* Import Scene Specific Components */
import TechnologyTree from "~sceneComponents/technologyTree/technologyTree"

/* Import Local Style(s) */
import * as styles from "./index.module.scss"

const ForceGraphPage = () => {
  return (
    <div className={`index ${styles.index}`}>
      {/* Header and Play Position */}
      <Header />
      <TechnologyTree startTime={0} endTime={154} />
    </div>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default ForceGraphPage
